import VueI18n from '../config/i18n';
// import InvoiceIndex from '../views/Invoice/Index.vue'
// import InvoiceShow from '../views/Invoice/Show.vue'
// import InvoiceForm from '../views/Invoice/Form.vue'
import {andGuard, orGuard} from '../guards/guards';

export default [
  {
    path: '/invoice',
    component: () => import('../views/Invoice/Index.vue'),
    name: 'Invoice',
    beforeEnter: orGuard,
    meta: {
      description: VueI18n.t('system.invoices'),
      breadcrumb: [{name: 'Invoice', title: 'breadcrumb.invoices'}],
      module: 'spain',
    },
  },
  {
    path: 'invoice/create',
    component: () => import('../views/Invoice/Form.vue'),
    name: 'Create Invoice',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.create_invoice'),
      breadcrumb: [{name: VueI18n.t('system.invoices'), title: 'breadcrumb.create_invoice'}, {
        name: VueI18n.t('system.create_invoice'),
        title: 'breadcrumb.create_invoice',
      }],
      permissions: ['create-invoice'],
      module: 'spain',
    },
  },
  {
    path: '/invoice/:invoiceId',
    component: () => import('../views/Invoice/Show.vue'),
    name: 'Show Invoice',
    meta: {
      description: VueI18n.t('system.showInvoice'),
      breadcrumb: [{name: VueI18n.t('system.invoices'), title: 'breadcrumb.invoices'}, {
        name: VueI18n.t('system.showInvoice'),
        title: 'breadcrumb.show_invoice',
      }],
      module: 'spain',
    }, //this route is secured by redirection from backend
  },
  {
    path: '/invoice/:invoiceId/edit',
    component: () => import('../views/Invoice/Form.vue'),
    name: 'Edit Invoice',
    meta: {
      description: VueI18n.t('system.editInvoice'),
      mode: 'edit',
      breadcrumb: [{name: VueI18n.t('system.invoices'), title: 'breadcrumb.invoices'}, {
        name: 'Show Invoice',
        title: 'breadcrumb.show_invoice',
      }, {name: VueI18n.t('system.editInvoice'), title: 'breadcrumb.edit_invoice'}],
      module: 'spain',
    }, //this route is secured by redirection from backend
  },
  // {
  //   path: '/invoice/:projectId/create',
  //   component: () => import('../views/Invoice/Form.vue'),
  //   name: 'Create Invoice from PRO',
  //   beforeEnter: andGuard,
  //   meta: {
  //     permissions: ['create-invoice'],
  //     description: VueI18n.t('system.create_invoice'),
  //     mode: 'createFV',
  //     breadcrumb: [{name: VueI18n.t('system.invoices'), title: 'breadcrumb.invoices'}, {
  //       name: 'Show Invoice',
  //       title: 'breadcrumb.list_invoice'
  //     }],
  //     module: 'spain'
  //   }
  // }
];
