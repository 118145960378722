import VueI18n from '../config/i18n';
// import KnowledgeBaseIndex from '../views/KnowledgeBase/Index.vue'
// import KnowledgeBaseShow from '../views/KnowledgeBase/Show.vue'
// import KnowledgeBaseForm from '../views/KnowledgeBase/Form.vue'
import {andGuard} from '../guards/guards';

export default [
  {
    path: '/knowledge_base',
    component: () => import('../views/KnowledgeBase/Index.vue'),
    name: 'KnowledgeBaseIndex',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.knowlage_base'),
      breadcrumb: [{name: VueI18n.t('system.knowlage_base'), title: 'breadcrumb.knowledge_base_index'}],
      permissions: ['knowledge-base-read'],
    },
  },
  {
    path: '/knowledge_base/create',
    component: () => import('../views/KnowledgeBase/Form.vue'),
    name: 'KnowledgeBase Create',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.knowledgeBaseCreate'),
      breadcrumb: [{name: VueI18n.t('system.knowlage_base'), title: 'breadcrumb.knowledge_base_index'},
        {name: VueI18n.t('system.knowledgeBaseCreate'), title: 'breadcrumb.knowledge_base_create'},
      ],
      permissions: ['knowledge-base-create'],
    },
  },
  {
    path: '/knowledge_base/:knowledgeBaseId/edit',
    component: () => import('../views/KnowledgeBase/Form.vue'),
    name: 'KnowledgeBase Edit',
    beforeEnter: andGuard,
    props: true,
    meta: {
      description: VueI18n.t('system.knowledgeBase_edit'),
      breadcrumb: [{name: VueI18n.t('system.knowlage_base'), title: 'breadcrumb.knowledge_base_index'},
        {
          name: VueI18n.t('system.knowledgeBase_show'),
          title: 'breadcrumb.knowledge_base_show',
        },
        {
          name: VueI18n.t('system.knowledgeBase_edit'),
          title: 'breadcrumb.knowledge_base_edit',
        },
      ],
      mode: 'edit',
      permissions: ['knowledge-base-update'],
    },
  },
  {
    path: '/knowledge_base/:knowledgeBaseId',
    component: () => import('../views/KnowledgeBase/Show.vue'),
    name: 'KnowledgeBaseShow',
    beforeEnter: andGuard,
    meta: {
      description: VueI18n.t('system.knowledgeBase_show'),
      breadcrumb: [{name: VueI18n.t('system.knowlage_base'), title: 'breadcrumb.knowledge_base_index'}, {
        name: VueI18n.t('system.knowledgeBase_show'),
        title: 'breadcrumb.knowledge_base_show',
      }],
      mode: 'edit',
      permissions: ['knowledge-base-read'],
    },
  },
];
